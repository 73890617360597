import React, { useState } from "react"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import PageHeader from "../../components/pageheader"
import Modal from "../../components/modal"
import * as styles from "../../styles/facilities.module.css"
import { replaceTag } from "../../utils/utils"
import { useLangContext } from "../../context/langContext"
import defaultImg from "../../images/facility-modal-default.jpg"

const ListIcon = ({ image }) => (
    <div
        style={{
            content: "",
            display: "block",
            width: "16px",
            height: "16px",
            position: "absolute",
            left: 0,
            top: "3px",
            background: `url(${image})`,
            backgroundSize: "100%",
        }}
    ></div>
)
const FacilitiesAlphabethPage = ({ pageContext }) => {
    const { lang } = useLangContext()
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [modalData, setModalData] = useState()

    let data = pageContext?.data
    if (lang === "id") {
        data = data?.translations.find((_, idx) => idx === 0)
    }
    const childData = data?.facilities?.nodes

    // group childData by first letter of title
    let result = childData.reduce((r, e) => {
        // get first letter of name of current element
        let group = e.title[0]
        // if there is no property in accumulator with this letter create it
        if (!r[group]) r[group] = { group, children: [e] }
        // if there is push current element to children array for that letter
        else r[group].children.push(e)
        // return accumulator
        return r
    }, {})
    let groupData = Object.values(result)
    // sort groupData
    groupData.sort((a, b) => {
        const groupA = a.group
        const groupB = b.group

        let res
        if (groupA > groupB) {
            res = 1
        } else if (groupA < groupB) {
            res = -1
        }

        return res
    })

    const openModal = childData => {
        setModalData(childData)
        setIsModalVisible(true)
    }
    const closeModal = () => setIsModalVisible(false)
    return (
        <Layout activeMenuId={4}>
            <Seo title={data?.name} />
            <PageHeader
                title={data?.name}
                subTitle={replaceTag(data?.description)}
                image={data?.acfFacilityCategory?.banner?.mediaItemUrl}
                breadcrumbs={[
                    lang === "en"
                        ? { label: "Facilities", link: "/facilities" }
                        : { label: "Fasilitas", link: "/id/facilities" },
                    { label: data?.name, link: null },
                ]}
            />
            <div className="bg-white">
                <div className={`mx-auto pt-4 pb-24 px-4 ${styles.wrap}`}>
                    {groupData.map((item, i) => {
                        return (
                            <section
                                key={i}
                                className={styles.alphabethSection}
                            >
                                <h2 className="font-serif text-5xl pb-4 mb-4">
                                    {item?.group}
                                </h2>
                                <ul className="">
                                    {item?.children.map(child => {
                                        return (
                                            <li
                                                key={`i-${child?.slug}`}
                                                className="inline-block w-1/2"
                                            >
                                                <button
                                                    className="py-4 pr-12"
                                                    onClick={() =>
                                                        openModal(child)
                                                    }
                                                >
                                                    {child?.title}
                                                </button>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </section>
                        )
                    })}
                </div>
            </div>
            <Modal
                noPadding
                width={450}
                isVisible={isModalVisible}
                closeModal={closeModal}
            >
                {modalData &&
                    modalData?.blocks.map((item, k) => {
                        const image = item?.colTextWImage?.img?.mediaItemUrl || defaultImg
                        return (
                            <React.Fragment key={k}>
                                <img
                                    src={image}
                                    alt={item?.colTextWImage?.img?.altText}
                                />
                                <div className={`p-4 ${styles.modalText}`}>
                                    <h3 className="text-3xl font-serif mb-2">
                                        {modalData?.colTextWImage?.title}
                                    </h3>
                                    <p className="mb-8">
                                        {item?.colTextWImage?.text}
                                    </p>
                                    <ul>
                                        {item?.colTextWImage?.list?.map(
                                            (l, i) => (
                                                <li key={i}>
                                                    <ListIcon
                                                        image={
                                                            l?.icon
                                                                ?.mediaItemUrl
                                                        }
                                                    />
                                                    {l?.text}
                                                </li>
                                            )
                                        )}
                                    </ul>
                                    <button
                                        className={`absolute ${styles.closeBtn}`}
                                        onClick={closeModal}
                                    >
                                        Close
                                    </button>
                                </div>
                            </React.Fragment>
                        )
                    })}
            </Modal>
        </Layout>
    )
}

export default FacilitiesAlphabethPage
