import * as React from "react"
import { AnimatePresence, motion } from "framer-motion"
import classnames from "classnames"

import * as styles from "../styles/modal.module.css"

const Modal = ({
  id,
  isVisible,
  closeModal,
  width = 250,
  noPadding = false,
  children,
}) => {
  const modalClass = classnames(
    `${styles.body} bg-white mx-auto mt-36 shadow-sm relative`,
    {
      "p-0": noPadding,
      "p-4": !noPadding,
    }
  )
  const escCloseModal = e => {
    if (e.keyCode === 27) {
      closeModal()
    }
  }
  return (
    <>
      <AnimatePresence>
        {isVisible && (
          <motion.div
            key={id}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 z-50"
          >
            <div
              className="overlay bg-opacity-50 bg-black absolute inset-0"
              onClick={closeModal}
              role="layout"
              onKeyDown={escCloseModal}
            />
            <div className="px-4">
              <div className={modalClass} style={{ maxWidth: `${width}px` }}>
                {children}
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  )
}

export default Modal
